import React, { useEffect, useState } from 'react';
import PostBlock from './post-block';
import styles from './post-grid.module.scss';


// ✅ store data 
// ✅ check through data for locations and return values that match given locations
// ✅ print out matching results
// ✅ then map through matching results and pass props to PostBlock
// ✅ link from PostBlock -> remoteok 
// maybe build open out accordian to show description

const PostGrid = () => {
    const [appState, setAppState] = useState({
        loading: false,
        data: [{
            company: '',
            company_logo: null,
            description: '',
            epoch: null,
            id: null,
            location: '',
            logo: null,
            position: '',
            slug: '',
            tags: [],
            url: '' 
        }],
    });
    console.log('appstate', appState.data)

    useEffect(() => {
        setAppState({ loading: true });
        const apiUrl = 'https://remoteok.com/api';
        fetch(apiUrl)
            .then((res) => res.json())
            .then((data) => {
                setAppState({ loading: false, data: data})
            });
    }, [setAppState]);

    console.log('appstate', appState.data)

    return (
        <>
            <div className={styles.root}>
            {
                appState.data && 
                    appState.data.map(job => (
                        job.company && (
                        job.location.includes('CET') || 
                        job.location.includes('Europe') || 
                        job.location.includes('UK') ||
                        job.location.includes('Global') ||
                        job.location.includes('Worldwide')) ?
                        <li key={job.id} className={styles.job}>
                            <PostBlock {...job} key={job.id}/>
                        </li> :
                        null
                    ))
            }
            </div>
        </>
    )
}

export default PostGrid;