import React from 'react';
import styles from './hero.module.scss';
import arrow from '../back.png';

const Hero = () => {
    return (
        <>  
            <div className={styles.root}>
                <h1 className={styles.title}>gwaith</h1>
                <img src={arrow} className={styles.arrow} alt=''></img>
                <p className={styles.copy}>Find your next remote tech job or project with an innovative company. In UK friendly timezones.</p>
            </div>
        </>
    )
}

export default Hero;