import React from 'react';
import styles from './post-block.module.scss';

const PostBlock = (job) => {

    //const tags = Children.toArray(job.tags)
    const dateCalc = (PostDate) => {
        const newPost = PostDate * 1000
        const diff = (new Date().getTime()) - newPost;
        return Math.ceil((((diff / 1000) / 60 ) / 60 ) / 24);
    };

    return (
        <>
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <div className={styles.companyLogo}>
                    {
                        job.company_logo ?
                        <img src={job.company_logo} className={styles.logo} alt=''></img> :
                        <div className={styles.noLogo}></div>
                    }
                </div>
            </div>
            <div className={styles.infoContainer}>
                {
                    dateCalc(job.epoch) < 4 ?
                    <h4>{job.company} <span className={styles.new}>NEW!</span></h4> :
                    null
                }
                <h3>{job.position}</h3>
                <p>{dateCalc(job.epoch)} days ago • {job.location}</p>
                <a className={styles.applyLink} href={`https://remoteok.com/remote-jobs/${job.slug}`}>View on remoteok.com</a>
            </div>
            {/* <div className={styles.tagsContainer}>
                <ul className={styles.tags}>
                    {
                        job.tags && tags.map((index, tag) => (
                            <li key={index} className={styles.tag}>{index}</li>
                        ))
                    }
                </ul>
            </div> */}
        </div>
        </>
    )
}

export default PostBlock;